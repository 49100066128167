import { currencyConvert, getCompanySalesName } from '@/util/functions'

const companySalesName = getCompanySalesName()

const pharmacyMoleculeListTableHeaders = [
    // { text: 'genericProductNumber', value: 'genericProductNumber' },
    { text: 'Generic Name', value: 'genericName' },

    // { text: 'Selected', value: 'Selected' },
    { text: 'Target', value: 'target' },

    {
        text: 'Quantity',
        value: 'DailyQtyDispenseSum',
    },
    {
        text: 'Generic Potential',
        value: 'salesSum',
        formatter(value) {
            return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
        },
    },

    {
        text: `${companySalesName} Sales`,
        value: 'taroSalesSum',
        formatter(value) {
            return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
        },
    },
    {
        text: `${companySalesName} Market Share`,
        value: 'marketShare',

    // formatter(value) {
    //     return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
    // },
    },
]

export default pharmacyMoleculeListTableHeaders
