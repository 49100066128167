<template>
  <div>
    <v-row class="tw-pb-3 match-height">
      <v-col
        v-if="$vuetify.breakpoint.smAndDown"
        cols="12"
        order="1"
      >
        <active-filter></active-filter>
      </v-col>
      <v-col
        cols="12"
        md="4"
        order="2"
      >
        <statistics-card-horizontal
          :stat-title="pharmacyGenericPotential.statTitle"
          :change="pharmacyGenericPotential.change"
          :color="pharmacyGenericPotential.color"
          :icon="pharmacyGenericPotential.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.total"
          :currency="true"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        md="4"
        order="3"
      >
        <statistics-card-horizontal
          :stat-title="pharmacyListStats.statTitle"
          :change="pharmacyListStats.change"
          :color="pharmacyListStats.color"
          :icon="pharmacyListStats.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.selectedMfrSales"
          :currency="true"
        ></statistics-card-horizontal>
      </v-col>
      <v-col
        cols="12"
        md="4"
        order="4"
      >
        <statistics-card-horizontal
          :stat-title="selectedMfrMarketShare.statTitle"
          :change="selectedMfrMarketShare.change"
          :color="selectedMfrMarketShare.color"
          :icon="selectedMfrMarketShare.icon"
          :statistics="dataStore.quickStats.totalGenericPotential.selectedMfrMarketShare"
        ></statistics-card-horizontal>
      </v-col>
    </v-row>
    <v-row class="match-height">
      <v-col
        ref="firstColumn"
        cols="12"
        md="6"
        lg="4"
        sm="12"
        order="5"
      >
        <div>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  offset-md="6"
                  md="6"
                >
                  <v-text-field
                    v-model="search"
                    :append-icon="icons.mdiMagnify"
                    label="Search"
                    single-line
                    hide-details
                    dense
                    outlined
                    class="pb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-data-table
                v-if="!$vuetify.breakpoint.smAndDown"
                v-model="filterStore.selectedPharmacy"
                :loading="pharmacyListStore.loading"
                :headers="pharmacyTableHeaders"
                :items="pharmacyListStore.pharmacyListBySales"
                item-key="Accreditation_Number"
                class="table-rounded"
                dense
                sort-by="sales"
                sort-desc
                :search="search"
                :single-select="filterStore.singleMode"
                :items-per-page="rowsPerPage"
                :footer-props="{
                  'items-per-page-options': [5, 10, 20, 50, 100, -1],
                }"
              >
                <template
                  v-for="header in pharmacyTableHeaders.filter(header => header.hasOwnProperty('formatter'))"
                  #[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <template #[`item.pharmacyName`]="{ item }">
                  <template>
                    <a
                      href="#"
                      class="tw-underline text-wrap"
                      style="white-space: normal; word-wrap: break-word;"
                      @click.prevent="goToPharmacyView(item)"
                    >
                      {{ item.pharmacyName }}
                    </a>
                  </template>
                </template>

                <template #[`item.marketShare`]="{ item }">
                  <apex-chart
                    type="pie"
                    width="100%"
                    :options="chartOptions"
                    :series="[item.marketShare, 100 - item.marketShare]"
                  ></apex-chart>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </div>
        <v-list v-if="$vuetify.breakpoint.smAndDown">
          <v-list-item
            v-for="item in pharmacyListStore.pharmacyListBySales"
            :key="item.Accreditation_Number"
            @click="goToPharmacyView(item)"
          >
            <v-list-item-content>
              <v-list-item-title class="tw-underline">
                {{ item.pharmacyName }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ currencyConvert(item.sales) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
        lg="4"
        class="pa-2"
        order="6"
      >
        <stacked-time-seriesmonthly-mfr series="monthlyStackedSalesByMfrTar"></stacked-time-seriesmonthly-mfr>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
        lg="4"
        class="pa-2"
        order="7"
      >
        <mfr-market-share-pie-chart-watch-list>
        </mfr-market-share-pie-chart-watch-list>
      </v-col>
    </v-row>

    <please-stand-by :loading="pharmacyListStore.loading"></please-stand-by>
  </div>
</template>

<script>
import {
    mdiStore, mdiSackPercent, mdiTrendingUp, mdiPoll, mdiCurrencyUsd, mdiMagnify
} from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'
import { useDataStore } from '@/stores/Data'
import { pharmacyTableHeaders } from '@/assets/headers/index'
import { currencyConvert, handleResize, getCompanySalesName } from '@/util/functions'
import { usePharmacyListStore } from '@/stores/PharmacyListStore'
import { ref } from 'vue'

export default {
    name: 'PharmacyList',
    components: {
        StatisticsCardHorizontal: () => import('@/@core/components/statistics-card/StatisticsCardHorizontal.vue'),
        MfrMarketSharePieChartWatchList: () => import('@/components/charts/MfrMarketSharePieChartWatchList.vue'),
        pleaseStandBy: () => import('@/components/dialog/pleaseStandBy.vue'),
        activeFilter: () => import('@/components/tables/activeFilter.vue'),
        stackedTimeSeriesmonthlyMfr: () => import('@/views/molecule/stackedTimeSeriesmonthlyMfr.vue')

    },
    setup() {
        const dataStore = useDataStore()
        const filterStore = useFilterStore()
        const companySalesName = getCompanySalesName()
        const pharmacyListStore = usePharmacyListStore()

        const pharmacyGenericPotential = {
            statTitle: 'Total Sales',
            statistics: '',
            change: '+',
            icon: mdiPoll,
            color: '#2196F3',
        }

        const pharmacyListStats = {
            statTitle: `${companySalesName} Sales`,
            statistics: '',
            change: '+',
            icon: mdiCurrencyUsd,
            color: '#4CAF50',
        }

        const selectedMfrMarketShare = {
            statTitle: 'Market Share',
            statistics: '',
            change: '+',
            icon: mdiSackPercent,
            color: 'error',
        }
        const search = ref('')

        return {
            currencyConvert,
            dataStore,
            pharmacyListStore,
            filterStore,
            pharmacyGenericPotential,
            pharmacyListStats,
            selectedMfrMarketShare,
            handleResize,
            search,
        }
    },
    data() {
        return {
            pharmacyTableHeaders,
            rowsPerPage: 5,
            chartOptions: {
                chart: {
                    type: 'pie',
                },
                labels: ['Company Sales', 'Others'],
                tooltip: {
                    enabled: true,
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false
                        },
                        expandOnClick: false
                    }
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
            },
            icons: {
                mdiMagnify,
                mdiStore,
                mdiSackPercent,
                mdiTrendingUp,
                mdiPoll,
                mdiCurrencyUsd,

            },
        }
    },
    methods: {
        async goToPharmacyView(item) {
            this.filterStore.selectedPharmacy = [item]
            await this.dataStore.fetchPharmacyMoleculeList()
            this.$router.push({ name: 'pharmacy-molecule-list' })
        },
    },
}
</script>
