import { currencyConvert, getCompanySalesName } from '@/util/functions'

// eslint-disable-next-line prefer-destructuring
const companySalesName = getCompanySalesName()

const pharmacyTableHeaders = [
    {
        text: 'Pharmacy',
        value: 'pharmacyName',
        align: 'left',
    },
    {
        text: 'Market Share',
        value: 'marketShare',
        align: 'left',
        width: '120px',
    },
    {
        text: `${companySalesName} sales`,
        value: 'companySales',
        align: 'left',
        formatter(value) {
            return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
        },
    },
    {
        text: 'Generic Potential',
        value: 'sales',
        align: 'left',
        formatter(value) {
            return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
        },
    },
]

export default pharmacyTableHeaders
